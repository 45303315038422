import React from "react";
import { Link } from "gatsby";

export const Empty = () => {
  return (
    <div>
      <p>Your cart is currently empty</p>
      <Link to={`/`}>Continue shopping</Link>
    </div>
  );
};
