import React, { useContext } from "react";
import styled from "styled-components";

// Context
import StoreContext from "../context/store";

// Utils
import { FormatProductTitle } from "../utils/format-product-title";

const Item = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 20px;
  margin: 0 0 100px 0;

  @media (max-width: 767px) {
    grid-column-gap: 10px;
    margin: 0 0 40px 0;
  }

  & .image-container {
    grid-column: span 2;
  }

  & .text-container {
    grid-column: span 4;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
    align-content: start;

    @media (max-width: 767px) {
      grid-column-gap: 10px;
    }

    & .product-title {
      grid-column: span 4;
      margin: 0 0 40px 0;

      & h2 {
        font-size: 19px;
        line-height: 26px;
        letter-spacing: 0.03em;
        text-transform: lowercase;

        @media (max-width: 600px) {
          font-size: 9px;
          line-height: 12px;
          letter-spacing: 0.03em;
        }
      }
    }

    & .price-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;

      @media (max-width: 767px) {
        grid-column-gap: 10px;
      }

      grid-column: span 2;
      align-items: baseline;

      & p,
      & button {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.02em;

        margin: 0;

        @media (max-width: 600px) {
          font-size: 9px;
          line-height: 12px;
          letter-spacing: 0.03em;
        }
      }
    }
  }
`;

export const SingleProduct = ({ product }) => {
  const context = useContext(StoreContext);

  const removeItem = () => {
    context.removeLineItem(
      context.store.client,
      context.store.checkout.id,
      product.id
    );
  };

  return (
    <Item key={`single_item_${product.id}`}>
      <div className="image-container">
        <img
          src={product.variant.image.src}
          alt={product.variant.image.altText}
          loading="lazy"
        />
      </div>

      <div className="text-container">
        <div className="product-title">
          <h2>
            {FormatProductTitle(product.title)[0]}
            <br />
            {FormatProductTitle(product.title)[1]}
          </h2>
        </div>

        <div className="price-container">
          <div className="price">
            <p>
              {/* {product.quantity} ×{" "} */}
              {new Intl.NumberFormat("en-GB", {
                style: "currency",
                currency: product.variant.priceV2.currencyCode,
              }).format(product.variant.priceV2.amount)}
            </p>
          </div>
          {/* <div className="price">
            <p>
              {new Intl.NumberFormat("en-GB", {
                style: "currency",
                currency: product.variant.priceV2.currencyCode,
              }).format(product.quantity * product.variant.priceV2.amount)}
            </p>
          </div> */}

          <div>
            <button type="button" onClick={removeItem}>
              (remove)
            </button>
          </div>
        </div>
      </div>
    </Item>
  );
};
