import React from "react";
import styled from "styled-components";

// Components
import { SingleProduct } from "./single-product";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;

  width: 100%;

  @media (max-width: 767px) {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 10px;

    margin: 0 0 30px 0;
  }

  & .products {
    grid-column: 2 / 8;

    @media (max-width: 1040px) {
      grid-column: 1 / 7;
    }
  }

  & .totals {
    grid-column: 9 / 13;
    position: relative;

    @media (max-width: 1040px) {
      grid-column: 7 / 13;
    }

    @media (max-width: 767px) {
      grid-column: 1 / 7;
    }

    & .sticky {
      position: sticky;
      top: 100px;

      @media (max-width: 767px) {
        position: relative;
        top: unset;
      }
    }

    & h1 {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.03em;

      @media (max-width: 600px) {
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.02em;
      }
    }

    & .flex {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: baseline;

      @media (max-width: 767px) {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 10px;
      }

      & h3,
      & p {
        flex: 50%;
        max-width: 50%;
        width: 100%;

        margin: 0;

        @media (max-width: 767px) {
          flex: unset;
          max-width: unset;
          grid-column: span 3;
        }
      }

      & .order-value {
        font-size: 19px;
        line-height: 26px;
        letter-spacing: 0.03em;

        @media (max-width: 600px) {
          font-size: 11px;
          line-height: 16px;
          letter-spacing: 0.02em;
        }
      }
    }

    & .checkout {
      color: rgba(159, 95, 85, 1);

      @media (max-width: 767px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;

        & a {
          grid-column: 2;
        }
      }
    }
  }
`;

export const Products = ({ checkout }) => (
  <Grid>
    <div className="products">
      {checkout.lineItems.map((product, index) => (
        <SingleProduct product={product} key={`single_bag_item_${index}`} />
      ))}
    </div>

    <div className="totals">
      <div className="sticky">
        <h1>bag total</h1>
        <br />

        <div className="flex">
          <h3 className="order-value">order value</h3>
          <p className="order-value">
            {new Intl.NumberFormat("en-GB", {
              style: "currency",
              currency: checkout.currencyCode,
            }).format(checkout.totalPrice.amount)}
          </p>
        </div>
        <div className="flex">
          <h3 className="order-value">delivery</h3>
          <p className="small-text">calculated at checkout</p>
        </div>
        <br />

        <div className="flex">
          <h3 className="order-value">total</h3>
          <p className="order-value">
            {new Intl.NumberFormat("en-GB", {
              style: "currency",
              currency: checkout.currencyCode,
            }).format(checkout.totalPrice.amount)}
          </p>
        </div>
        <br />

        <div className="checkout">
          <a href={checkout.webUrl} className="order-value">
            (checkout)
          </a>
        </div>
      </div>
    </div>
  </Grid>
);
