import React, { useContext } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import StoreContext from "../components/context/store";

// Components
import { Products } from "../components/cart/products";
import { Empty } from "../components/cart/cart-empty";

const Page = styled.div`
  padding: 200px 0 0 0;

  @media (max-width: 767px) {
    padding: 105px 0 0 0;
  }
`;

const Bag = () => {
  const context = useContext(StoreContext);
  const { checkout } = context.store;

  return (
    <>
      <Helmet
        title={`Bag | Metamorphoses`}
        meta={[
          {
            name: "title",
            content: `Bag | Metamorphoses`,
          },
          {
            property: "og:title",
            content: `Bag | Metamorphoses`,
          },
          {
            property: "twitter:title",
            content: `Bag | Metamorphoses`,
          },
          {
            property: "og:url",
            content: `https://metamorphosesobjects.com/bag/`,
          },
          {
            property: "twitter:url",
            content: `https://metamorphosesobjects.com/bag/`,
          },
        ]}
      >
        {/* <link rel="canonical" href={`https://metamorphosesobjects.com/bag/`} /> */}
      </Helmet>
      <Page>
        {checkout.lineItems.length !== 0 ? (
          <Products checkout={checkout} />
        ) : (
          <Empty />
        )}
      </Page>
    </>
  );
};

export default withPreview(Bag);
